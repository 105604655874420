let _JoyState = { forward: 0, back: 0, left: 0, right: 0, pitchUp: 0, pitchDown: 0, yawLeft: 0, yawRight: 0};

export class JoyStick {

    constructor(options = {}) {
        const joyOptions1 = {
            zone: document.getElementById('joystickWrapper1'),
            size: 120,
            multitouch: true,
            maxNumberOfNipples: 2,
            mode: 'static',
            restJoystick: true,
            shape: 'circle',
            // position: { top: 20, left: 20 },
            position: { top: '60px', left: '60px' },
            dynamicPage: true
        };

        this.joyManager1 = nipplejs.create(joyOptions1);
        this.joyManager1['0'].on('move', function (event, data) {
            const forward = data.vector.y;
            const turn = data.vector.x;
            //console.log(forward, turn);
            if (forward > 0) {
                _JoyState.forward = Math.abs(forward);
                _JoyState.back = 0;
            } else if (forward < 0) {
                _JoyState.forward = 0;
                _JoyState.back = Math.abs(forward);
            }
      
            if (turn > 0) {
                _JoyState.left = 0;
                _JoyState.right = Math.abs(turn);
            } else if (turn < 0) {
                _JoyState.left = Math.abs(turn);
                _JoyState.right = 0;
            }

        });
      
        this.joyManager1['0'].on('end', function (event) {
            _JoyState.back = 0;
            _JoyState.forward = 0;
            _JoyState.left = 0;
            _JoyState.right = 0;
        });

        const joyOptions2 = {
            zone: document.getElementById('joystickWrapper2'),
            size: 120,
            multitouch: true,
            maxNumberOfNipples: 2,
            mode: 'static',
            restJoystick: true,
            shape: 'circle',
            // position: { top: 20, left: 20 },
            position: { top: '60px', left: '60px' },
            dynamicPage: true
        };

        this.joyManager2 = nipplejs.create(joyOptions2);
        this.joyManager2['0'].on('move', function (event, data) {
            const forward = data.vector.y;
            const turn = data.vector.x;
            //console.log(forward, turn);
            if (forward > 0) {
                _JoyState.pitchUp = Math.abs(forward);
                _JoyState.pitchDown = 0;
            } else if (forward < 0) {
                _JoyState.pitchUp = 0;
                _JoyState.pitchDown = Math.abs(forward);
            }
      
            if (turn > 0) {
                _JoyState.yawLeft = 0;
                _JoyState.yawRight = Math.abs(turn);
            } else if (turn < 0) {
                _JoyState.yawLeft = Math.abs(turn);
                _JoyState.yawRight = 0;
            }

        });
      
        this.joyManager2['0'].on('end', function (event) {
            _JoyState.pitchDown = 0;
            _JoyState.pitchUp = 0;
            _JoyState.yawLeft = 0;
            _JoyState.yawRight = 0;
        });
    }
    
    showJoyStickInfo() {
		return _JoyState;
	}

}
