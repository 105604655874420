import { fadeElement } from './Util.js';

const STANDARD_FADE_DURATION = 500;

export class LoadingSpinner {

    static elementIDGen = 0;

    constructor(message, container, options = {}) {

        this.taskIDGen = 0;
        this.elementID = LoadingSpinner.elementIDGen++;

        // Set the background color
        this.bgcolor = options.bgcolor !== undefined ? options.bgcolor : 'black';

        this.tasks = [];

        this.message = message || 'Loading... Please Wait';
        this.container = container || document.body;

        this.spinnerContainerOuter = document.createElement('div');
        this.spinnerContainerOuter.className = `spinnerOuterContainer${this.elementID}`;
        this.spinnerContainerOuter.style.display = 'none';

        this.spinnerContainerPrimary = document.createElement('div');
        this.spinnerContainerPrimary.className = `spinnerContainerPrimary${this.elementID}`;
        this.spinnerPrimary = document.createElement('div');
        this.spinnerPrimary.classList.add(`spinner${this.elementID}`, `spinnerPrimary${this.elementID}`);
        this.messageContainerPrimary = document.createElement('div');
        this.messageContainerPrimary.classList.add(`messageContainer${this.elementID}`, `messageContainerPrimary${this.elementID}`);
        this.messageContainerPrimary.innerHTML = this.message;

        this.spinnerContainerMin = document.createElement('div');
        this.spinnerContainerMin.className = `spinnerContainerMin${this.elementID}`;
        this.spinnerMin = document.createElement('div');
        this.spinnerMin.classList.add(`spinner${this.elementID}`, `spinnerMin${this.elementID}`);
        this.messageContainerMin = document.createElement('div');
        this.messageContainerMin.classList.add(`messageContainer${this.elementID}`, `messageContainerMin${this.elementID}`);
        this.messageContainerMin.innerHTML = this.message;

        this.spinnerContainerPrimary.appendChild(this.spinnerPrimary);
        this.spinnerContainerPrimary.appendChild(this.messageContainerPrimary);
        this.spinnerContainerOuter.appendChild(this.spinnerContainerPrimary);

        this.spinnerContainerMin.appendChild(this.spinnerMin);
        this.spinnerContainerMin.appendChild(this.messageContainerMin);
        this.spinnerContainerOuter.appendChild(this.spinnerContainerMin);


        const style = document.createElement('style');
        if (this.bgcolor == 0) {
            style.innerHTML = `
                .spinnerOuterContainer${this.elementID} {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    top: 0;
                    left: 0;
                    position: absolute;
                    pointer-events: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .messageContainer${this.elementID} {
                    height: auto;
                    font-family: 'Gabarito', sans-serif;
                    font-size: 16pt;
                    color: #ffffff;
                    text-align: center;
                    vertical-align: middle;
                }
                .spinner${this.elementID} {
                    padding: 15px;
                    background-image: url('assets/images/loading_black.gif'); 
                    background-size: contain;
                    z-index: 99999;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
                .spinnerContainerPrimary${this.elementID} {
                    z-index: 99999;
                    background-color: rgba(0, 0, 0, 1);
                    width: auto;
                    pointer-events: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    margin: 0;
                }
                .spinnerPrimary${this.elementID} {
                    width: 400px;
                }
                .messageContainerPrimary${this.elementID} {
                    padding-top: 15px;
                }
                .spinnerContainerMin${this.elementID} {
                    z-index: 99999;
                    background-color: rgba(0, 0, 0, 1);
                    padding-top: 20px;
                    padding-bottom: 15px;
                    margin: 0;
                    position: absolute;
                    bottom: 50px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    display: flex;
                    flex-direction: left;
                    pointer-events: auto;
                    min-width: 250px;
                }
                .messageContainerMin${this.elementID} {
                    margin-right: 15px;
                }
                .spinnerMin${this.elementID} {
                    width: 50px;
                    height: 50px;
                    margin-left: 15px;
                    margin-right: 25px;
                }
                .messageContainerMin${this.elementID} {
                    padding-top: 15px;
                }
                @keyframes load {
                    to { transform: rotate(1turn); }
                }
                @keyframes rotate {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                @keyframes fade {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }
            `;
        } else if (this.bgcolor == 1) {
            style.innerHTML = `
                .spinnerOuterContainer${this.elementID} {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    top: 0;
                    left: 0;
                    position: absolute;
                    pointer-events: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .messageContainer${this.elementID} {
                    height: auto;
                    font-family: 'Gabarito', sans-serif;
                    font-size: 16pt;
                    color: #000000; /* Black text for white background */
                    text-align: center;
                    vertical-align: middle;
                }
                .spinner${this.elementID} {
                    padding: 15px;
                    background-image: url('assets/images/loading_white.gif'); 
                    background-size: contain;
                    z-index: 99999;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
                .spinnerContainerPrimary${this.elementID} {
                    z-index: 99999;
                    background-color: rgba(255, 255, 255, 1); /* White background */
                    width: auto;
                    pointer-events: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    margin: 0;
                }
                .spinnerPrimary${this.elementID} {
                    width: 400px;
                }
                .messageContainerPrimary${this.elementID} {
                    padding-top: 15px;
                }
                .spinnerContainerMin${this.elementID} {
                    z-index: 99999;
                    background-color: rgba(255, 255, 255, 1); /* White background */
                    padding-top: 20px;
                    padding-bottom: 15px;
                    margin: 0;
                    position: absolute;
                    bottom: 50px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    display: flex;
                    flex-direction: row; /* Changed from 'left' to 'row' */
                    pointer-events: auto;
                    min-width: 250px;
                }
                .messageContainerMin${this.elementID} {
                    margin-right: 15px;
                    color: #000000; /* Black text for white background */
                }
                .spinnerMin${this.elementID} {
                    width: 50px;
                    height: 50px;
                    margin-left: 15px;
                    margin-right: 25px;
                }
                .messageContainerMin${this.elementID} {
                    padding-top: 15px;
                }
                @keyframes load {
                    to { transform: rotate(1turn); }
                }
                @keyframes rotate {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                @keyframes fade {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0; }
                }
            `;
        }
        this.spinnerContainerOuter.appendChild(style);
        this.container.appendChild(this.spinnerContainerOuter);

        this.setMinimized(false, true);

        this.fadeTransitions = [];
    }

    addTask(message) {
        const newTask = {
            'message': message,
            'id': this.taskIDGen++
        };
        this.tasks.push(newTask);
        this.update();
        return newTask.id;
    }

    removeTask(id) {
        let index = 0;
        for (let task of this.tasks) {
            if (task.id === id) {
                this.tasks.splice(index, 1);
                break;
            }
            index++;
        }
        this.update();
    }

    removeAllTasks() {
        this.tasks = [];
        this.update();
    }

    setMessageForTask(id, message) {
        for (let task of this.tasks) {
            if (task.id === id) {
                task.message = message;
                break;
            }
        }
        this.update();
    }

    update() {
        if (this.tasks.length > 0) {
            this.show();
            this.setMessage(this.tasks[this.tasks.length - 1].message);
        } else {
            this.hide();
        }
    }

    show() {
        this.spinnerContainerOuter.style.display = 'flex';
        this.visible = true;
    }

    hide() {
        this.spinnerContainerOuter.style.display = 'none';
        this.visible = false;
    }

    setContainer(container) {
        if (this.container) {
            this.container.removeChild(this.spinnerContainerOuter);
        }
        if (container) {
            this.container = container;
            this.container.appendChild(this.spinnerContainerOuter);
            this.spinnerContainerOuter.style.zIndex = this.container.style.zIndex + 1;
        }
    }

    setMinimized(minimized, instant) {
        const showHideSpinner = (element, show, instant, displayStyle, fadeTransitionsIndex) => {
            if (instant) {
                element.style.display = show ? displayStyle : 'none';
            } else {
                this.fadeTransitions[fadeTransitionsIndex] = fadeElement(element, !show, displayStyle, STANDARD_FADE_DURATION, () => {
                    this.fadeTransitions[fadeTransitionsIndex] = null;
                });
            }
        };
        showHideSpinner(this.spinnerContainerPrimary, !minimized, instant, 'flex', 0);
        showHideSpinner(this.spinnerContainerMin, minimized, instant, 'flex', 1);
        this.minimized = minimized;
    }

    setMessage(msg) {
        this.messageContainerPrimary.innerHTML = msg;
        this.messageContainerMin.innerHTML = msg;
    }
}
