export class LineHelpPanel {

    constructor(container) {

        this.container = container || document.body;

        this.linehelpCells = {};

        const layout = [
            ['Key K', 'Add Line'],
            ['Key M', 'Delete Line'],
            ['Key J', 'Edit Line'],
            ['Key N', 'Edit Scale (when point is selected)'],
            ['Key B', 'Show Line information'],
            ['Key =', 'Increase Line Scale (in edit mode)'],
            ['Key -', 'Decrease Line Scale (in edit mode)']
        ];
        

        this.linehelpPanelContainer = document.createElement('div');
        const style = document.createElement('style');
        style.innerHTML = `

            .linehelpPanel {
                width: 300px;
                padding: 10px;
                background-color: rgba(50, 50, 50, 0.85);
                border: #555555 2px solid;
                color: #dddddd;
                border-radius: 10px;
                z-index: 9999;
                font-family: arial;
                font-size: 10pt;
                text-align: left;
                margin: 0;
                bottom: 10px;
                left:10px;
                position: absolute;
                pointer-events: auto;
            }

            .linehelp-panel-cell {
                margin-bottom: 5px;
                padding-bottom: 2px;
            }

            .linehelp-label-cell {
                font-weight: bold;
                font-size: 10pt;
                width: 50px;
            }

        `;
        this.linehelpPanelContainer.append(style);

        this.linehelpPanel = document.createElement('div');
        this.linehelpPanel.className = 'linehelpPanel';

        const linehelpTable = document.createElement('div');
        linehelpTable.style.display = 'table';

        for (let layoutEntry of layout) {
            const row = document.createElement('div');
            row.style.display = 'table-row';
            row.className = 'linehelp-panel-row';

            const labelCell = document.createElement('div');
            labelCell.style.display = 'table-cell';
            labelCell.innerHTML = `${layoutEntry[0]}: `;
            labelCell.classList.add('linehelp-panel-cell', 'linehelp-label-cell');

            const spacerCell = document.createElement('div');
            spacerCell.style.display = 'table-cell';
            spacerCell.style.width = '10px';
            spacerCell.innerHTML = ' ';
            spacerCell.className = 'linehelp-panel-cell';

            const linehelpCell = document.createElement('div');
            linehelpCell.style.display = 'table-cell';
            linehelpCell.innerHTML = `${layoutEntry[1]}`;
            linehelpCell.className = 'linehelp-panel-cell';

            this.linehelpCells[layoutEntry[1]] = linehelpCell;

            row.appendChild(labelCell);
            row.appendChild(spacerCell);
            row.appendChild(linehelpCell);

            linehelpTable.appendChild(row);
        }

        this.linehelpPanel.appendChild(linehelpTable);
        this.linehelpPanelContainer.append(this.linehelpPanel);
        this.linehelpPanelContainer.style.display = 'none';
        this.container.appendChild(this.linehelpPanelContainer);

        this.visible = false;
    }

    setContainer(container) {
        if (this.container) {
            this.container.removeChild(this.linehelpPanelContainer);
        }
        if (container) {
            this.container = container;
            this.container.appendChild(this.linehelpPanelContainer);
            this.linehelpPanelContainer.style.zIndex = this.container.style.zIndex + 1;
        }
    }

    show() {
        this.linehelpPanelContainer.style.display = 'block';
        this.visible = true;
    }

    hide() {
        this.linehelpPanelContainer.style.display = 'none';
        this.visible = false;
    }

}

