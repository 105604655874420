import { Viewer } from '../Viewer.js';

export class button {

    constructor(container, options = {}) {

        this.container = container || document.body;
        this.viewer = Viewer;
        this.infoCells = {};

        const layout = [
            [options.function + '<u>(' + options.key + ')</u>: On'],
        ];
        // Set the background color
        this.bgcolor = options.bgcolor !== undefined ? options.bgcolor : 'black';

        this.infoPanelContainer = document.createElement('div');
        const style = document.createElement('style');
        if (this.bgcolor == 0) {
            style.innerHTML = `
                .infoPanel_1 {
                    width: 100%;
                    max-width: 141px; /* Max width for larger screens */
                    padding: 10px;
                    background-color: rgba(50, 50, 50, 0.85);
                    border: #555555 2px solid;
                    color: #dddddd;
                    border-radius: 10px;
                    z-index: 9999;
                    font-family: arial;
                    font-size: 11pt;
                    text-align: center;
                    margin: 0;
                    top: 10px;
                    left: 10px;
                    position: absolute;
                    pointer-events: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box; /* Include padding and border in width/height */
                }
    
                .info-panel-row {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    width: 100%; /* Ensure rows take full width */
                }
    
                .label-cell {
                    font-weight: bold;
                    font-size: 12pt;
                    margin: 0 10px;
                    word-break: break-word; /* Break words if they are too long */
                }
    
                .toggle-button {
                    padding: 5px;
                    background-color: #4e5a6700; 
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 14px;
                    margin: 0;
                    width: 100%; 
                    box-sizing: border-box; 
                    transition: background-color 0.3s; 
                    white-space: nowrap; 
                    overflow: hidden; 
                    text-overflow: ellipsis;
                }
                
                .toggle-button:hover {
                    background-color: rgba(78, 90, 103, 0.3);
                }
    
                 /* Media queries for responsiveness */
                @media (max-width: 834px) {
                    .infoPanel_1 {
                        font-size: 9pt; /* Reduce font size on smaller screens */
                        padding: 8px; /* Adjust padding */
                        top: 5px; /* Adjust positioning */
                        right: 5px; /* Adjust positioning */
                    }
                
                    .toggle-button {
                        font-size: 12px; /* Reduce font size on buttons */
                        padding: 6px 10px; /* Adjust padding on buttons */
                    }
                }
                
                @media (max-width: 480px) {
                    .infoPanel_1 {
                        font-size: 8pt; /* Further reduce font size on very small screens */
                        padding: 6px; /* Adjust padding */
                        top: 5px; /* Adjust positioning */
                        right: 5px; /* Adjust positioning */
                    }
                
                    .toggle-button {
                        font-size: 10px; /* Further reduce font size on buttons */
                        padding: 5px 8px; /* Adjust padding on buttons */
                    }
                }
                
            `;
        } else if (this.bgcolor == 1) {
            style.innerHTML = `
                .infoPanel_1 {
                    width: 100%;
                    max-width: 141px; /* Max width for larger screens */
                    padding: 10px;
                    background-color: rgba(240, 240, 240, 0.85); /* Light gray background */
                    border: #cccccc 2px solid; /* Light gray border */
                    color: #333333; /* Dark gray text for contrast */
                    border-radius: 10px;
                    z-index: 9999;
                    font-family: Arial, sans-serif;
                    font-size: 11pt;
                    text-align: center;
                    margin: 0;
                    top: 10px;
                    left: 10px;
                    position: absolute;
                    pointer-events: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box; /* Include padding and border in width/height */
                }
    
                .info-panel-row {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    width: 100%; /* Ensure rows take full width */
                }
                
                .label-cell {
                    font-weight: bold;
                    font-size: 12pt;
                    margin: 0 10px;
                    word-break: break-word; /* Break words if they are too long */
                    color: #000000; /* Black text color */
                }
    
                .toggle-button {
                    padding: 5px;
                    // background-color: #dcdcdc; /* Light gray background */
                    color: #000000; /* Black text color */
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 14px;
                    margin: 0;
                    width: 100%; 
                    box-sizing: border-box; 
                    transition: background-color 0.3s; 
                    white-space: nowrap; 
                    overflow: hidden; 
                    text-overflow: ellipsis;
                }
                
                // .toggle-button:hover {
                //     background-color: rgba(78, 90, 103, 0.3);
                // }
    
                 /* Media queries for responsiveness */
                @media (max-width: 834px) {
                    .infoPanel_1 {
                        font-size: 9pt; /* Reduce font size on smaller screens */
                        padding: 8px; /* Adjust padding */
                        top: 5px; /* Adjust positioning */
                        right: 5px; /* Adjust positioning */
                    }
                
                    .toggle-button {
                        font-size: 12px; /* Reduce font size on buttons */
                        padding: 6px 10px; /* Adjust padding on buttons */
                    }
                }
                
                @media (max-width: 480px) {
                    .infoPanel_1 {
                        font-size: 8pt; /* Further reduce font size on very small screens */
                        padding: 6px; /* Adjust padding */
                        top: 5px; /* Adjust positioning */
                        right: 5px; /* Adjust positioning */
                    }
                
                    .toggle-button {
                        font-size: 10px; /* Further reduce font size on buttons */
                        padding: 5px 8px; /* Adjust padding on buttons */
                    }
                }
                
            `;
        }
        this.infoPanelContainer.append(style);

        this.infoPanel = document.createElement('div');
        this.infoPanel.className = 'infoPanel_1';

        const infoTable = document.createElement('div');
        infoTable.style.display = 'table';

        for (let layoutEntry of layout) {
            const row = document.createElement('div');
            row.style.display = 'table-row';
            row.className = 'info-panel-row';

            const labelCell = document.createElement('div');
            labelCell.style.display = 'table-cell';
            labelCell.innerHTML = `${layoutEntry[0]}`;
            labelCell.classList.add('toggle-button', 'label-cell');
            labelCell.id = 'toggleBoundingBoxButton';

            row.appendChild(labelCell);
            infoTable.appendChild(row);
        }

        this.infoPanel.appendChild(infoTable);
        this.infoPanelContainer.append(this.infoPanel);
        this.infoPanelContainer.style.display = 'none';
        this.container.appendChild(this.infoPanelContainer);

        this.visible = false;
    }

    setContainer(container) {
        if (this.container) {
            this.container.removeChild(this.infoPanelContainer);
        }
        if (container) {
            this.container = container;
            this.container.appendChild(this.infoPanelContainer);
            this.infoPanelContainer.style.zIndex = this.container.style.zIndex + 1;
        }
    }

    show() {
        this.infoPanelContainer.style.display = 'block';
        this.visible = true;
    }

    hide() {
        this.infoPanelContainer.style.display = 'none';
        this.visible = false;
    }

}
