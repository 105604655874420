export class HelpPanel {

    constructor(container) {

        this.container = container || document.body;

        this.helpCells = {};

        const layout = [
            ['Mouse Left Click + drag', 'Orbit (svo=1,3,4)'],
            ['Mouse Right Click + drag', 'Pan (svo=1,3,4)'],
            ['Mouse Right Click', 'Set Focal Point (svo=1)'],
            ['Key H', 'Show Help'],
            ['Key I', 'Show Information (copy Camera Info)'],
            ['Left Arrow', 'Rotate Object Counterclockwise (svo=1)'],
            ['Right Arrow', 'Rotate Object Clockwise (svo=1)'],
            ['Key O', 'Change Camera Mode (Perspective/Orthographic)'],
            ['Key G', 'Show Bounding Box Size'], 
            ['Key K', 'Add/Show Line (svo=1,3)'],
            //['Key M', 'Delete Line (valid when K on)'],
            //['Key J', 'Edit Line (valid when K on)'],
            //['Key N', 'Edit Scale (valid when J on)'],
            //['Key B', 'Show Line information (valid when K on)'],
            ['Key L', 'Set Camera Up (svo=1)'],
            ['Key X', 'Increase Focal Adjustment'],
            ['Key Z', 'Decrease Focal Adjustment'],
            ['Key =', 'Increase Splat Scale (svo=1,3,4)/Move Speed (svo=2,5)'],
            ['Key -', 'Decrease Splat Scale (svo=1,3,4)/Move Speed (svo=2,5)'],
            //['Key C', 'Show Mesh Cursor'],
            //['Key U', 'Show Control Plane'],
            //['Key P', 'Show Point Cloud Mode'],
            //['Key L, '']
        ];
        

        this.helpPanelContainer = document.createElement('div');
        const style = document.createElement('style');
        style.innerHTML = `

            .helpPanel {
                width: 550px;
                padding: 10px;
                background-color: rgba(50, 50, 50, 0.85);
                border: #555555 2px solid;
                color: #dddddd;
                border-radius: 10px;
                z-index: 9999;
                font-family: arial;
                font-size: 10pt;
                text-align: left;
                margin: 0;
                bottom: 10px;
                right:10px;
                position: absolute;
                pointer-events: auto;
            }

            .help-panel-cell {
                margin-bottom: 5px;
                padding-bottom: 2px;
            }

            .help-label-cell {
                font-weight: bold;
                font-size: 10pt;
                width: 180px;
            }

        `;
        this.helpPanelContainer.append(style);

        this.helpPanel = document.createElement('div');
        this.helpPanel.className = 'helpPanel';

        const helpTable = document.createElement('div');
        helpTable.style.display = 'table';

        for (let layoutEntry of layout) {
            const row = document.createElement('div');
            row.style.display = 'table-row';
            row.className = 'help-panel-row';

            const labelCell = document.createElement('div');
            labelCell.style.display = 'table-cell';
            labelCell.innerHTML = `${layoutEntry[0]}: `;
            labelCell.classList.add('help-panel-cell', 'help-label-cell');

            const spacerCell = document.createElement('div');
            spacerCell.style.display = 'table-cell';
            spacerCell.style.width = '10px';
            spacerCell.innerHTML = ' ';
            spacerCell.className = 'help-panel-cell';

            const helpCell = document.createElement('div');
            helpCell.style.display = 'table-cell';
            helpCell.innerHTML = `${layoutEntry[1]}`;
            helpCell.className = 'help-panel-cell';

            this.helpCells[layoutEntry[1]] = helpCell;

            row.appendChild(labelCell);
            row.appendChild(spacerCell);
            row.appendChild(helpCell);

            helpTable.appendChild(row);
        }

        this.helpPanel.appendChild(helpTable);
        this.helpPanelContainer.append(this.helpPanel);
        this.helpPanelContainer.style.display = 'none';
        this.container.appendChild(this.helpPanelContainer);

        this.visible = false;
    }

    setContainer(container) {
        if (this.container) {
            this.container.removeChild(this.helpPanelContainer);
        }
        if (container) {
            this.container = container;
            this.container.appendChild(this.helpPanelContainer);
            this.helpPanelContainer.style.zIndex = this.container.style.zIndex + 1;
        }
    }

    show() {
        this.helpPanelContainer.style.display = 'block';
        this.visible = true;
    }

    hide() {
        this.helpPanelContainer.style.display = 'none';
        this.visible = false;
    }

}

